<template>
  <v-form
    ref="form"
    class="rcc-form"
    @submit.prevent="onSubmitForm"
    v-model="valid"
  >
    <div class="rcc-form__form-body">
      <template v-if="isSupplierRole">
        <rcc-text-input
          label="Имя"
          width="40%"
          v-model="form.fio"
          :rules="[value => isRequiredField({ value, field: 'Имя' })]"
        />

        <rcc-text-input
          label="Email"
          width="40%"
          v-model="form.email"
          :rules="[value => isRequiredField({ value, field: 'Email' })]"
        />
      </template>

      <section v-if="!isSupplierRole">
        <h2>
          Оповещения
        </h2>
        <rcc-checkbox label="Получать оповещения на почту" v-model="form.send_email" />
      </section>

      <section>
        <h2>
          Сменить пароль
        </h2>
        <!-- <rcc-text-input
          label="Старый пароль"
          width="40%"
          v-model="form.old_password"
          type="password"
          :rules="[value => (!form.password && !passwordRepeat) || isRequiredField({ value, field: 'Старый пароль' })]"
        /> -->

        <rcc-text-input
          label="Новый пароль"
          width="40%"
          v-model="form.password"
          type="password"
          @input="handlePasswordInput"
          :rules="[
            value => (!passwordRepeat) || isRequiredField({ value, field: 'Новый пароль' })
          ]"
        />

        <rcc-text-input
          ref="passwordRepeat"
          label="Новый повторно"
          width="40%"
          v-model="passwordRepeat"
          type="password"
          :rules="[
            value => (!passwordRepeat) || isRequiredField({ value, field: 'Повтор пароля' }),
            passwordRepeatValidate
          ]"
        />
      </section>
    </div>

    <rcc-footer-buttons :is-submit="isSubmit" @cancel-click="$router.push({ name: 'returns' })" />
  </v-form>
</template>

<script>
// import ProfileApi from '@/api/user-profile'
import Page from '@/mixins/page'
import Form from '@/mixins/form'

import RccTextInput from 'Components/controls/rcc-text-input'
import RccCheckbox from 'Components/controls/rcc-checkbox'
import RccFooterButtons from 'Components/layouts/rcc-footer-buttons'

export default {
  components: {
    RccTextInput,
    RccFooterButtons,
    RccCheckbox
  },

  mixins: [Page, Form],

  data() {
    return {
      passwordRepeat: '',

      form: {
        // old_password: '',
        send_email: null,
        password: ''
      }
    }
  },

  computed: {
    isSupplierRole() {
      return this.$store.user.role === this.$constants.users.supplier
    }
  },

  created() {
    this.$apiMethods.userProfile.get()
      .then(userData => {
        this.form = { ...userData, password: '' }
      })
  },

  methods: {
    onSubmit() {
      this.isSubmit = true

      this.$apiMethods.userProfile.update(this.form)
        .then(() => {
          window.snackbar('Настройки профиля успешно изменены', 'info')
        })
        .finally(() => { this.afterSubmit() })
    },

    handlePasswordInput() {
      if (this.passwordRepeat) {
        this.$refs.passwordRepeat.$refs.inputRef.validate(true)
      }
    },

    passwordRepeatValidate(value) {
      return value === this.form.password || 'Пароли не совпадают'
    }
  }
}
</script>

<style lang="scss" scoped>
.rcc-users-profile-form {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__form {
    flex-grow: 1;
    padding: $base-form-padding;
  }
}
</style>
